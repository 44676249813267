import React, {useState} from "react";
import {useSelector} from "react-redux";
import {RootState, useAppDispatch} from "../store";
import {addOwnedProject, closeModal, createProject} from "./projectManagementSlice";
import {CreationStatus} from "./interfaces";



export const CreateModal: React.FC = () => {
    const dispatch = useAppDispatch();
    const pm = useSelector((state: RootState) => state.projectManagement);
    const display = pm.createModalOpen ? 'd-block' : 'hidden';
    const [newProjectName, setNewProjectName] = useState<string>("");

    return (
        <div className={`${display} fixed top-1/4 left-1/4 z-10 w-3/5 h-1/2 bg-white border border-black rounded-xl`}>
            <section className="flex flex-col p-2 ">
                <input onChange={(e) => setNewProjectName(e.target.value)} type="text" placeholder="Enter New Project" />
                <div>
                    <button type="button" onClick={() => dispatch(closeModal())}>
                        Close
                    </button>
                    <button type="button" onClick={() => {
                        console.log(`Creating ${newProjectName}`)
                        dispatch(createProject({Name: newProjectName}))
                        dispatch(closeModal())
                    }}>
                        Create
                    </button>
                </div>
            </section>
        </div>
    );
};