import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { Provider } from 'react-redux';
import { store } from './store';
import Header from './Header';
import Login from './Login';
import Register from './Register';
import Home from './Home';
import Search from './Search';
import Results from './Results';
import UserPortal from './UserPortal';
import {ProjectManagementMain} from "./ProjectManagement/projectManagementMain";

const App = () => {
  return (
    <Provider store={store}>
      <Router>
        <div className="App">
          <Routes>
            <Route path="/login" element={
              <>
                <Header />
                <Login />
              </>
            } />
            <Route path="/register" element={
              <>
                <Header />
                <Register />
              </>
            }  />
            <Route path="/" element={
              <>
                <Header />
                <Home />
              </>
            }  />
            <Route path="/search" element={
              <>
                <Header />
                <Search />
              </>
            }  />
            <Route path="/results" element={
              <>
                <Header />
                <Results />
              </>
            }  />
            <Route path="/user-portal" element={
              <>
                <Header />
                <UserPortal />
              </>
            } />
            <Route path="project-management" element={<ProjectManagementMain/>} />
          </Routes>
        </div>
      </Router>
    </Provider>
  );
};

export default App;
