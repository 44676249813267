import {useEffect, useState} from "react";
import axiosInstance from "../axiosInstance";
import {ProjectCard} from "./ProjectCard";
import {Project, ProjectResponse} from "./interfaces";
import {CreateModal} from "./CreateModal";
import {useNavigate} from "react-router-dom";
import {useSelector} from "react-redux";
import {RootState, useAppDispatch} from "../store";
import {openModal, setColabProjects, setOwnedProjects} from "./projectManagementSlice";
import {ProjectManagementFeeds} from "./ProjectManagementFeeds";
import {ProjectView} from "./ProjectView";




export const ProjectManagementMain = () => {
    const [loading, setLoading] = useState<boolean>(true);
    const dispatch = useAppDispatch();
    const pm = useSelector((state: RootState) => state.projectManagement);

    return (
      <div className="flex flex-col h-screen p-6 items-center">
          <div className="flex justify-between w-full">
              <h6>Projects</h6>
              <button>
                  Grid
              </button>
          </div>
          {
              pm.currentProject === "" ? <ProjectManagementFeeds /> : <ProjectView />
          }
          <CreateModal/>
      </div>
    )
}