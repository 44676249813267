import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit';

import axiosInstance from '../axiosInstance';
import axios from "axios";
import {useNavigate} from "react-router-dom";
import {CreateProjectResponse, CreationStatus, Project, ProjectCreation} from "./interfaces";
import {AsyncThunkConfig} from "@reduxjs/toolkit/dist/createAsyncThunk";

export const createProject = createAsyncThunk(
    'projectManagement/createProject',
    async (projectData: ProjectCreation, thunkAPI) => {

        try {
            const token = localStorage.getItem("token")

            const response = await axiosInstance.post<CreateProjectResponse>(
                '/api/v1/project', projectData, {
                    headers: { Authorization: `Bearer ${token}` }
                });

            return response.data.data;

        } catch (error) {
            console.error(error);
            if (axios.isAxiosError(error)) {
                if (error.response) {
                    if (error.response.status === 401) {
                        const navigate = useNavigate();
                        navigate('/login');
                    } else {
                        return thunkAPI.rejectWithValue(error.response.data);
                    }
                }
            }
        }
    }
);

interface ProjectManagementState {
    createModalOpen: boolean;
    error: string;
    status: string;
    ownedProjects: Project[],
    colabProjects: Project[],
    creationStatus: CreationStatus,
    currentProject: string
}

const initialState: ProjectManagementState = {
    createModalOpen: false,
    error: "",
    status: "",
    ownedProjects: [],
    colabProjects: [],
    creationStatus: CreationStatus.Success,
    currentProject: "",
}


const projectManagementSlice = createSlice({
    name: 'projectManagement',
    initialState: initialState,
    reducers: {
        openModal: (state) => {
            return {...state, createModalOpen: true};
        },
        closeModal: (state) => {
            return {...state, createModalOpen: false};
        },
        setOwnedProjects: (state, action: PayloadAction<Project[]>) => {
            state.ownedProjects = action.payload
        },
        setColabProjects: (state, action: PayloadAction<Project[]>) => {
            state.colabProjects = action.payload
        },
        addOwnedProject: (state, action) => {
            state.ownedProjects.push(action.payload)
        },
        addColabProject: (state, action) => {
            state.colabProjects.push(action.payload)
        },
        setCurrentProject: (state, action) => {
            state.currentProject = action.payload
        }
    },
    extraReducers: (builder) => {
        // The `builder` callback form is used here because it provides correctly typed reducers from the action creators
        builder.addCase(createProject.fulfilled, (state, { payload }) => {
            console.log(payload);
            state.creationStatus = CreationStatus.Success;
            if (payload)
                state.ownedProjects.push(payload);
        })
        builder.addCase(createProject.rejected, (state, action) => {
            if (action.payload) {
                //state.error = action.payload
                state.status = "rejected"
            } else if (action.error.message) {
                state.error = action.error.message
            }
        })
        builder.addCase(createProject.pending, (state, action) => {
            state.status = "pending"
        })
    },
});

export const { openModal, closeModal, setOwnedProjects, setColabProjects, addOwnedProject, addColabProject,
                setCurrentProject} = projectManagementSlice.actions;
export default projectManagementSlice.reducer;
