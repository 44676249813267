

export interface User {
    ID: number,
    CreatedAt: string,
    UpdatedAt: string,
    DeletedAt: string,
    username: string;
    password: string;
}

export interface Project {
    ID: number;
    Name: string;
    CreatedAt: string;
    UpdatedAt: string;
    DeletedAt: string;
    UserID: number;
}

export interface ProjectCreation {
    Name: string;
}

export interface ProjectResponse {
    owned: Project[];
    colab: Project[];
    message: string;
}

export interface CreateProjectResponse {
    message: string;
    data: Project;
}

export enum CreationStatus {
    Success = 1,
    Failed ,
    Pending = 2,
}