import {useSelector} from "react-redux";
import {RootState} from "../store";


export const ActivityFeed = () => {
    const pm = useSelector((state: RootState) => state.projectManagement);


    return (
        <div className="flex flex-col flex-grow w-1/6">
            <div>
                <h1>Recent</h1>
            </div>
            <div>
                <h1>Activity</h1>
            </div>
        </div>
    )
}