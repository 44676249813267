import {Project} from "./interfaces";
import React from "react";
import {useAppDispatch} from "../store";
import {setCurrentProject} from "./projectManagementSlice";

interface IProjectCard {
    name: string;
}


export const ProjectCard: React.FC<IProjectCard> = ({name}) => {
    const dispatch = useAppDispatch()
    return (
        <div className="w-full flex p-2 border border-black rounded m-1" onClick={() => dispatch(setCurrentProject(name))}>
            <h6>{name}</h6>
        </div>
    )
}