import { configureStore } from '@reduxjs/toolkit';
import authReducer from './authSlice';
import projectManagementReducer from './ProjectManagement/projectManagementSlice';
import {useDispatch} from "react-redux";

export const store = configureStore({
  reducer: {
    auth: authReducer,
    projectManagement: projectManagementReducer
  },
});

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch

export const useAppDispatch = () => useDispatch<AppDispatch>()
