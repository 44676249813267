import {useSelector} from "react-redux";
import {RootState} from "../store";
import {useState} from "react";


export const ProjectView = () => {
    const pm = useSelector((state: RootState) => state.projectManagement);
    const [projectView, setProjectView] = useState<string>("Overview")


    return (
        <div className="flex flex-col h-full w-full">
            <div className="flex gap-2 items-center">
                <h6>{pm.currentProject}</h6>
                <select name="view" id="project-view" className="p-2 rounded"
                        onChange={(e) => setProjectView(e.target.value)}>
                    <option value="overview">Overview</option>
                    <option value="design">Design</option>
                    <option value="estimate">Estimate</option>
                </select>
            </div>
            <div className="flex gap-2 items-center mt-2">

            </div>
        </div>
    )
}