

import {useSelector} from "react-redux";
import {RootState} from "../store";


export const Calender = () => {
    const pm = useSelector((state: RootState) => state.projectManagement);


    return (
        <div className="flex flex-col flex-grow w-1/6">
            <h1>Calender</h1>
        </div>
    )
}