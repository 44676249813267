import {useSelector} from "react-redux";
import {RootState, useAppDispatch} from "../store";
import {openModal, setColabProjects, setOwnedProjects} from "./projectManagementSlice";
import {ProjectCard} from "./ProjectCard";
import {useEffect, useState} from "react";
import axiosInstance from "../axiosInstance";
import {ProjectResponse} from "./interfaces";
import {useNavigate} from "react-router-dom";


export const ProjectFeed = () => {
    const [loading, setLoading] = useState<boolean>(true);

    const dispatch = useAppDispatch();
    const pm = useSelector((state: RootState) => state.projectManagement);

    const projects = pm.ownedProjects
    const colabProjects = pm.colabProjects

    const navigate = useNavigate()


    useEffect(() => {
        const fetchData = async () =>{
            setLoading(true);
            try {
                const token = localStorage.getItem("token")
                const response = await axiosInstance.get<ProjectResponse>(
                    '/api/v1/project', { headers: { Authorization: `Bearer ${token}` }}).then(r => {
                    dispatch(setOwnedProjects(r.data.owned))
                    dispatch(setColabProjects(r.data.colab))
                    setLoading(false);
                    console.log(projects);
                });
            } catch (error) {
                // @ts-ignore
                if (error.response.status === 401) {
                    navigate("/login")
                }
            }
            setLoading(false);
        }
        fetchData();
    }, []);

    return (
        <div className="flex flex-col grow-[2] items-center h-3/4 w-4/5">
            <div className="flex justify-between w-full p-2">
                <h6>Projects</h6>
                <button
                    className="px-4 py-2 border border-black text-black rounded-md hover:bg-white hover:text-black transition duration-300"
                    onClick={() => dispatch(openModal())}>
                    New Project
                </button>
            </div>
            <div
                className="border border-black flex flex-col justify-center items-center h-full w-full p-2 overflow-y-scroll overflow-x-hidden">
                {
                    projects.map((p) => {
                        return <ProjectCard name={p.Name}/>
                    })
                }
            </div>
        </div>
    )
}